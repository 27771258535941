import { BaseService } from 'services';
import { COMMENT_MODE, SERVICES } from '../constants';
import axios from 'axios';

const baseService = new BaseService(SERVICES.DA_SERVICE_URL);
const productService = new BaseService(SERVICES.PRODUCT_SERVICE_URL);
const assessorService = new BaseService(SERVICES.INSURER_SERVICE_URL);

type dataEmail = {
    mail_to: Array<string>;
    mail_cc: Array<string>;
    subject: string;
    content: string;
    attachment: Array<string>;
};

const announcementService = {
    getListAnnouncement: (keyword: string, limit: number = 10, page: number) =>
        baseService.instance
            .get('/announcements', {
                params: {
                    ...(!!keyword && { keyword: keyword?.trim() }),
                    limit,
                    page,
                },
            })
            .then((response) => response.data),
    uploadAttachment: (data: { fileName: string; contentType: string }[]) =>
        baseService.instance.post('/announcements/file/upload', data).then((response) => response.data),
    putFileBySignedURL: (url: string, contentType: string, file: File) =>
        axios.put(url, file, {
            headers: {
                'Content-Type': contentType,
            },
        }),
    getAnnouncementById: (id: string) => baseService.instance.get(`/announcements/${id}`),
    createAnnouncement: (data: {
        title: string;
        content_html: string;
        target_providers: string[];
        release_date: string;
        files?: { url: string; content_type: string; name: string; id: string }[];
    }) => baseService.instance.post('/announcements', data),
    updateAnnouncement: (data: {
        id: string;
        title: string;
        content_html: string;
        target_providers: string[];
        release_date: string;
        files?: { url: string; content_type: string; name: string; id: string }[];
    }) => baseService.instance.put(`/announcements/${data.id}`, data),
    delete: (id: string) => baseService.instance.delete(`/announcements/${id}`),
};

const groupService = {
    getList: () => baseService.instance.get('/groups'),
    detail: (groupName: string) => baseService.instance.get(`/group/${groupName}`),
    create: (data: any) => baseService.instance.post('/groups', data),
    update: (groupName: string, data: any) => baseService.instance.put(`/groups/${groupName}`, data),
    delete: (groupName: string) => baseService.instance.delete(`/groups/${groupName}`),
};

const policyService = {
    getList: (name?: string | null, limit: number = 10, nextToken: string | null = null) =>
        baseService.instance.get('/policy', {
            params: { ...(!!name && { name: name?.trim() }), limit, nextToken },
        }),
    detail: (id: string) => baseService.instance.get(`/policy/${id}`),
    create: (data: any) => baseService.instance.post('/policy', data),
    update: (id: string, data: any) => baseService.instance.put(`/policy/${id}`, data),
    delete: (id: string) => baseService.instance.delete(`/policy/${id}`),
};

const specialtyService = {
    getList: (name?: string | null, limit: number = 10, page: number = 1) =>
        baseService.instance.get('/specialty', {
            params: { ...(!!name && { search_name: name?.trim() }), limit, page },
        }),
    download: (isSample?: boolean) => baseService.instance.get(isSample ? `/specialty/sample` : `/specialty/download`),
};

const symptomService = {
    getList: (name?: string, limit: number = 10, page: number = 1) =>
        baseService.instance.get('/symptoms', {
            params: { ...(!!name && { search_name: name?.trim() }), limit, page },
        }),
    download: (isSample?: boolean) => baseService.instance.get(isSample ? `/symptoms/sample` : `/symptoms/download`),
    getAll: (params: any = { all: true, status: 'Active' }) =>
        baseService.instance.get('/symptoms', {
            params: params,
        }),
    filter: (ids: Array<string> = []) =>
        baseService.instance.post('/symptoms/filter', {
            ids: ids,
        }),
    search: (name: string = '', limit: number = 100, page: number = 1) =>
        baseService.instance.get('/symptoms', {
            params: {
                page,
                name,
                limit,
            },
        }),
};

const diagnosisService = {
    get: (id: string) => baseService.instance.get(`/diagnosis/${id}`),
    update: ({ id, ...data }: NodeJS.Dict<string>) => baseService.instance.put(`/diagnosis/${id}`, data),
    create: (data: any) => baseService.instance.post(`/diagnosis`, data),
    getList: (name?: string, limit: number = 10, page: number = 1) => {
        name = name?.trim();
        return baseService.instance.get('/diagnosis', {
            params: { ...(name && { name }), limit, page },
        });
    },
    download: (isSample?: boolean) => baseService.instance.get(isSample ? `/diagnosis/sample` : `/diagnosis/download`),
};

const tospService = {
    getList: (name?: string, policy_id?: string, limit: number = 10, page: number = 1) =>
        baseService.instance.get('/tosp', {
            params: {
                ...(!!name && { tosp_code: name?.trim() }),
                ...(!!policy_id && { policy_id }),
                limit,
                page,
            },
        }),
    getListWithoutPagination: (name: string, limit: number = 100, page: number = 1) =>
        baseService.instance.get('/tosp', {
            params: {
                ...(!!name ? { name } : {}),
                limit,
            },
        }),
    download: (isSample?: boolean, policy_id?: any) =>
        baseService.instance.get(isSample ? `/tosp/sample` : `/tosp/download`, {
            params: {
                policy_id,
            },
        }),
    getAll: () =>
        baseService.instance.get(`/tosp`, {
            params: { all: true, status: 'Active' },
        }),
    createOrUpdateForCRUD: (data: any) => baseService.instance.post('/tosp/create-or-update-for-crud', data),
    getByIds: (data: any) => baseService.instance.post('/tosp/get-by-ids', data),
};

const rateService = {
    getList: (name?: string, limit: number = 10, nextToken: string | null = null) =>
        baseService.instance.get('/rate', {
            params: { ...(!!name && { name: name?.trim() }), limit, nextToken },
        }),
    update: (id: string, data: any) => baseService.instance.put(`/rate/${id}`, data),
    getAll: (params: any = { all: true }) =>
        baseService.instance.get('/rate', {
            params: params,
        }),
};

const adminService = {
    getList: (full_name: string, group: string, limit: number = 10, nextToken: string | null = null) =>
        baseService.instance.get('/admin', {
            params: {
                ...(!!full_name && { full_name: full_name?.trim() }),
                ...(!!group && { group }),
                limit,
                nextToken,
            },
        }),
    getListWithoutPagination: (full_name: string, role: string, limit: number = 1000, view: string = '') =>
        baseService.instance.get('/admin', {
            params: {
                ...(!!full_name && { full_name: full_name?.trim() }),
                ...(!!role && { role }),
                limit,
                status: 'Active',
                view: view,
            },
        }),
    profile: () => baseService.instance.get('/admin/profile'),
    register: (data: any) => baseService.instance.post('/admin/register', data),
    update: (id: any, data: any) => baseService.instance.put(`/admin/${id}`, data),
    invite: (data: any) => baseService.instance.post('/admin/invite', data),
    resend: (data: any) => baseService.instance.post('/admin/resend', data),
    delete: (id: any) => baseService.instance.delete(`/admin/${id}`),
    detail: (id: any) => baseService.instance.get(`/admin/${id}`),
    getForSignup: (id: any) => baseService.instance.get(`/admin/get-for-signup/${id}`),
    unblock: (reference_id: string) => baseService.instance.delete(`/admin/blocked/${reference_id}`),
};

const errorService = {
    getList: (type: string, limit: number = 10, page: number = 1, reference_id?: string) =>
        baseService.instance.get('/errors', {
            params: { reference_id, limit, page },
        }),
    getLogs: (type: string) =>
        baseService.instance.get('/logs', {
            params: { type },
        }),
};

const homeService = {
    getListCase: (
        name: string,
        user_id: string,
        role: string,
        claim_status: string,
        sortDesc: string,
        sortAsc: string,
        limit: number = 10,
        page: number,
        status?: string,
        form_type?: string,
    ) =>
        productService.instance.get(`/cases`, {
            params: {
                ...(!!name && { name: name?.trim() }),
                ...(!!user_id && { user_id: user_id?.trim() }),
                ...(!!role && { role: role?.trim() }),
                ...(!!claim_status && { claim_status: claim_status?.trim() }),
                ...(!!sortDesc && { sortDesc: sortDesc?.trim() }),
                ...(!!sortAsc && { sortAsc: sortAsc?.trim() }),
                limit,
                page,
                ...(!!status && { status: status?.trim() }),
                ...(!!form_type && { form_type }),
                isAdmin: true,
            },
        }),
    getListCaseArchive: (
        name: string,
        claim_status: string,
        sortDesc: string,
        sortAsc: string,
        limit: number = 10,
        page: number,
        status?: string,
        startDate?: number | null,
        endDate?: number | null,
    ) =>
        productService.instance.get(`/cases`, {
            params: {
                ...(!!name && { name: name?.trim() }),
                ...(!!claim_status && { claim_status: claim_status?.trim() }),
                ...(!!sortDesc && { sortDesc: sortDesc?.trim() }),
                ...(!!sortAsc && { sortAsc: sortAsc?.trim() }),
                ...(!!startDate && { startDate: startDate }),
                ...(!!endDate && { endDate: endDate }),
                limit,
                page,
                ...(!!status && { status: status?.trim() }),
                isAdmin: true,
            },
        }),
    reassign: (case_id: any, data: any) => productService.instance.put(`/cases/reassign/${case_id}`, data),
};

const allocationService = {
    getList: (name: string, limit: number = 100, nextToken: string | null = null) =>
        baseService.instance.get(`/allocations`, {
            params: {
                ...(!!name && { name: name?.trim() }),
                limit,
                nextToken,
            },
        }),
    create: (data: any) => baseService.instance.post(`/allocations`, data),
    update: (id: any, data: any) => baseService.instance.put(`/allocations/${id}`, data),
    detail: (id: string) => baseService.instance.get(`/allocations/${id}`),
};

const assessorServices = {
    getPatient: (id: string) => assessorService.instance.get(`/patients/get-for-admin/${id}`),
    getAssessorPDF: (case_id: string) => baseService.instance.get(`/case-export-section/${case_id}`),
    postRequestExport: (case_id: string) =>
        baseService.instance.post('/case-export-section/export-assessment', {
            case_id,
        }),
    getDoctorPACFormA: (id: string) => baseService.instance.get(`/pac/formA/${id}`),
    getDoctorPACFormB: (id: string) => baseService.instance.get(`/pac/formB/${id}`),
    getDoctorPACFormC: (id: string) => baseService.instance.get(`/pac/formC/${id}`),
    getListComments: (id: string, isInternal?: boolean) => {
        const modes = isInternal ? [COMMENT_MODE.INTERNAL] : [COMMENT_MODE.DOCTOR, COMMENT_MODE.DRAFT];

        return baseService.instance.get(`/pac/${id}/comments?${modes.map((mode) => 'mode=' + mode).join('&')}`);
    },
    getListFilesUpload: (case_id: string) => baseService.instance.get(`/pac/${case_id}/files`),
    sendComment: (data: any) => baseService.instance.post(`/pac/comments`, data),
    sendDocumentComment: (form_id: string, comment_id: string, data: any) =>
        baseService.instance.put(`/pac/${form_id}/comments/${comment_id}/update-document`, data),
    deleteDocumentComment: (form_id: string, comment_id: string, document_id: string) =>
        baseService.instance.delete(`/pac/${form_id}/comments/${comment_id}/delete-document/${document_id}`),
    deleteComment: (id: string) => baseService.instance.delete(`/pac/comments/${id}`),
    getUnreadComments: (form_id: string) => baseService.instance.get(`pac/form/${form_id}/unread-comments`),
    getReadCommentsByCaseId: (case_id: string) => baseService.instance.get(`pac/cases/${case_id}/unread-comments`),
    markReadCommentsByFormId: (form_id: string) => baseService.instance.post(`pac/form/${form_id}/mark-read-comments`),
    markReadCommentsByListCommentIds: (ids: Array<string>) =>
        baseService.instance.post(`/pac/mark-read-comment-by-ids`, {
            ids: ids || [],
        }),
    deletefile: (case_id: string, id: string) => baseService.instance.delete(`/pac/${case_id}/files/${id}`),
    postFiles: (case_id: string, data: any) => baseService.instance.post(`/pac/${case_id}/files`, data),
    updateFormB: (data: any, case_id: string) => baseService.instance.put(`/pac/formB/${case_id}`, data),
    updateClaimStatus: (data: { claim_status: string }, case_id: string) =>
        baseService.instance.put(`/pac/statuses/change/${case_id}`, data),
    updateCase: async (case_id: string, data: any) => baseService.instance.put(`/pac/${case_id}/submit-form`, data),
    reIssueCase: async (case_id: string, data: any) => productService.instance.post(`/cases/${case_id}`, data),
    updatePatient: async (id: string, data: any) => baseService.instance.put(`/case-patients/${id}`, data),
    getDetailCase: (case_id: string) => baseService.instance.get(`/pac/cases/${case_id}`),
    updateTick: (case_id: string, data: any) => baseService.instance.put(`/pac/cases/${case_id}/update-tick`, data),
    getAllTosp: (params: any = { all: true, status: 'Active' }) =>
        baseService.instance.get('/tosp', {
            params: params,
        }),
    filterTosp: (ids: Array<string> = []) =>
        baseService.instance.post('/tosp/filter', {
            ids: ids,
        }),
    searchTosp: (name: string = '', limit: number = 100, page: number = 1) =>
        baseService.instance.get('/tosp', {
            params: {
                page,
                name,
                limit,
            },
        }),
    downloadFilePdf: (case_id: any, data: any) => baseService.instance.post(`/pac/${case_id}/download-cert`, data),
    getCaseLogs: async (case_id: string) => baseService.instance.get(`/pac/${case_id}/case-logs`),
    getDefaultDocuments: async (case_id: string) =>
        baseService.instance.get(`/case-files/${case_id}/default-attachment`),
    getCaseEmail: async (case_id: string) => baseService.instance.get(`/case-email?case_id=${case_id}`),
    getDataFileNonClinic: async (case_id: string, default_no?: number) =>
        baseService.instance.get(`/case-email/params/${case_id}`, {
            params: {
                ...(!!default_no && { default_no: default_no }),
            },
        }),
    updateCaseEmail: async (
        case_id: string,
        data: {
            case_id: string;
            mail_patient: Array<dataEmail>;
            mail_hospital: Array<dataEmail>;
        },
    ) => baseService.instance.put(`/case-email/${case_id}`, data),
    createCaseEmail: async (data: {
        case_id: string;
        mail_patient: Array<dataEmail>;
        mail_hospital: Array<dataEmail>;
    }) => baseService.instance.post(`/case-email`, data),
    getFileEncryption: async (case_id: string, url: string) =>
        baseService.instance.get(`/case-email/download-file?case_id=${case_id}&url=${url}`),
    getFileDefaultWithRecomendationNo: async (case_id: string, default_no: number) =>
        baseService.instance.get(`/case-files/${case_id}?default_no=${default_no}`),
    postFileDefaultWithRecomendationNo: async (
        case_id: string,
        data: {
            html: string;
            default_no: number;
            file_name: string;
            preview_html: string;
        },
    ) => baseService.instance.post(`/case-files/${case_id}`, data),

    getAssessmentResult: (id: string) => baseService.instance.get(`/pac/stp/result-detail/${id}`),
    resendSMS: async (case_id: string, phone_number: string) =>
        baseService.instance.post(`/pac/resend-sms/${case_id}`, {
            phone_number
        }),
};

const importStatusService = {
    getList: (type: string) =>
        baseService.instance.get(`/import-status`, {
            params: { type },
        }),
};

const providerService = {
    doctorInfo: (name: string, status: string = 'Active', limit: number = 100) =>
        baseService.instance.get('/doctor-info', {
            params: {
                ...(!!name && { name: name?.trim() }),
                status: 'Active',
                limit,
            },
        }),
    doctorBasicInfoList: (name: string = '', status: string = 'Active') =>
        baseService.instance
            .get('/doctors/basic-info-list', {
                params: {
                    ...(!!name && { name: name?.trim() }),
                    status,
                },
            })
            .then((response) => response.data?.data),
    importProvider: (data: any) => baseService.instance.post(`/doctors/import`, data),
    getFileSampleImportProvider: () => baseService.instance.get(`/doctors/sample`),
    unblock: (reference_id: string) => baseService.instance.delete(`/doctors/blocked/${reference_id}`),
};

const analyticService = {
    getList: (name?: string, limit: number = 10, page: number = 1) =>
        baseService.instance.get('/allocations', {
            params: { ...(!!name && { name: name?.trim() }), limit, page },
        }),
    stpReport: ({ timeout = 60000, ...dates }) =>
        baseService.instance.get(`/analytics/export-stp-report?${new URLSearchParams(dates)}`, {
            responseType: 'blob',
            timeout,
        }),
    download: (isSample?: boolean) => baseService.instance.get(isSample ? `/symptoms/sample` : `/symptoms/download`),
    exportPacInssuanceBreakdown: (startDate: string, endDate: string, timeOffset: any, type: string, unit: string) =>
        baseService.instance.get('/analytics/export-pac-issuance-breakdown', {
            params: { startDate, endDate, timeOffset, type, unit },
        }),
    exportTATPerformance: (startDate: string, endDate: string, timeOffset: any, option: String, unit: string) =>
        baseService.instance.get('analytics/export-tat-performance', {
            params: { startDate, endDate, timeOffset, option, unit },
        }),
    exportReports: (
        reportType: string,
        dateType: string,
        statuses: string,
        startDate: string | null,
        toDate: string | null,
        timezone: string,
    ) =>
        baseService.instance.get('analytics/export-case-report', {
            params: { reportType, dateType, statuses, startDate, toDate, timezone },
        }),
    exportPACIssuance: (startDate: string, endDate: string, timeOffset: any, unit: string) =>
        baseService.instance.get('analytics/export-pac-issuance', {
            params: { startDate, endDate, timeOffset, unit },
        }),
    financeReport: ({ timeout = 60000, ...dates }) =>
        baseService.instance.get(`/analytics/export-monthly-finance?${new URLSearchParams(dates)}`, {
            responseType: 'blob',
            timeout,
        }),
    tppReport: ({ timeout = 60000, ...dates }) =>
        baseService.instance.get(`/analytics/export-monthly-tpp?${new URLSearchParams(dates)}`, {
            responseType: 'blob',
            timeout,
        }),
};

const historyPACService = {
    getHistoryCase: (
        case_id: string,
        limit: number = 10,
        page: number = 1,
        nextToken: string | null = null,
        statusFilter?: string,
    ) =>
        baseService.instance.get(`/pac/cases-history/${case_id}`, {
            params: {
                limit,
                page,
                nextToken,
                ...(!!statusFilter && { status: statusFilter?.trim() }),
            },
        }),
    getHistoryCaseDetail: (version_id: string, case_id: string) =>
        baseService.instance.get(`/pac/history/${version_id}/cases/${case_id}`),
    getHistoryCaseFormA: (version_id: string, case_id: string) =>
        baseService.instance.get(`/pac/history/${version_id}/formA/${case_id}`),
    getHistoryCaseFormB: (version_id: string, case_id: string) =>
        baseService.instance.get(`/pac/history/${version_id}/formB/${case_id}`),
    getHistoryCaseFormC: (version_id: string, case_id: string) =>
        baseService.instance.get(`/pac/history/${version_id}/formC/${case_id}`),
    getHistoryCaseListComments: (version_id: string, form_id: string, isInternal?: boolean) => {
        const modes = isInternal ? [COMMENT_MODE.INTERNAL] : [COMMENT_MODE.DOCTOR, COMMENT_MODE.DRAFT];

        return baseService.instance.get(
            `/pac/history/${version_id}/form/${form_id}/comments?${modes.map((mode) => 'mode=' + mode).join('&')}`,
        );
    },
    getHistoryCaseListFiles: (version_id: string, case_id: string) =>
        baseService.instance.get(`/pac/history/${version_id}/cases/${case_id}/files`),
    getHistoryCasePatient: (version_id: string, id: string) =>
        baseService.instance.get(`/pac/history/${version_id}/patients/${id}`),
    getCaseEmail: async (version_id: string, case_id: string) =>
        baseService.instance.get(`/case-email?case_id=${case_id}&version_id=${version_id}`),
};

const v2Service = {
    export: (id: string) => productService.instance.post(`/v2/cases/${id}`),
    reset: (id: string) => productService.instance.delete(`/v2/cases/${id}`),
    searchHospital: (name: string = '') =>
        productService.instance.post(`/v2/search-hospital?${new URLSearchParams({ name })}`, {}),
};

const DAService = {
    announcements: announcementService,
    group: groupService,
    policy: policyService,
    specialty: specialtyService,
    symptom: symptomService,
    diagnosis: diagnosisService,
    tosp: tospService,
    rate: rateService,
    admin: adminService,
    errors: errorService,
    home: homeService,
    allocation: allocationService,
    assessor: assessorServices,
    importStatus: importStatusService,
    providers: providerService,
    analytics: analyticService,
    historyPAC: historyPACService,
    v2: v2Service,
};

export default DAService;
